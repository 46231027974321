var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "tit": "제품문의",
      "bg": "/images/sub/visual/sv--inquiry.jpg"
    }
  }), _c('section', {
    staticClass: "section secondary color-white"
  }, [_c('v-container', [_c('div', {
    staticClass: "d-flex align-end justify-space-between mb-20 mb-lg-40"
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold letter-spacing-6 line-height-1"
  }, [_vm._v(" INQUIRY ")]), _c('v-spacer'), _vm.catalog ? _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "transparent text-btn d-flex align-center px-4",
    staticStyle: {
      "border-radius": "0!important"
    },
    attrs: {
      "small": "",
      "plain": "",
      "tile": "",
      "color": "white",
      "href": `/res/catalog/${_vm.catalog.filename}`,
      "target": "_blank"
    }
  }, [_vm._v(" 일반 카탈로그 "), _c('i', {
    staticClass: "icon icon-download"
  })])], 1) : _vm._e(), _vm.catalog2 ? _c('v-col', {
    staticClass: "ml-12 pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "transparent text-btn d-flex align-center px-4",
    staticStyle: {
      "border-radius": "0!important"
    },
    attrs: {
      "small": "",
      "plain": "",
      "tile": "",
      "color": "white",
      "href": `/res/catalog2/${_vm.catalog2.filename}`,
      "target": "_blank"
    }
  }, [_vm._v(" 아트배너 스탠드 카탈로그 "), _c('i', {
    staticClass: "icon icon-download"
  })])], 1) : _vm._e()], 1), _c('div', [_c('v-row', {
    staticClass: "row--large"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    ref: "name",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "label": "이름"
    },
    model: {
      value: _vm.input.name,
      callback: function ($$v) {
        _vm.$set(_vm.input, "name", $$v);
      },
      expression: "input.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    ref: "email",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "label": "이메일"
    },
    model: {
      value: _vm.input.email,
      callback: function ($$v) {
        _vm.$set(_vm.input, "email", $$v);
      },
      expression: "input.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    ref: "companyName",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "label": "회사명"
    },
    model: {
      value: _vm.input.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.input, "companyName", $$v);
      },
      expression: "input.companyName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    ref: "phone",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "label": "전화번호"
    },
    model: {
      value: _vm.input.phone,
      callback: function ($$v) {
        _vm.$set(_vm.input, "phone", $$v);
      },
      expression: "input.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    ref: "type",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "label": "문의내용"
    },
    model: {
      value: _vm.input.type,
      callback: function ($$v) {
        _vm.$set(_vm.input, "type", $$v);
      },
      expression: "input.type"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-lg-50 d-flex justify-center align-center"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "개인정보 처리방침에 동의합니다.",
      "hide-details": ""
    },
    model: {
      value: _vm.agree,
      callback: function ($$v) {
        _vm.agree = $$v;
      },
      expression: "agree"
    }
  }), _c('v-btn', {
    staticClass: "ml-6",
    attrs: {
      "onclick": "window.open('/privacy', '', 'width=483,height=700');",
      "small": "",
      "text": "",
      "color": "grey-7"
    }
  }, [_vm._v("약관보기")])], 1), _c('div', {
    staticClass: "mt-50 mt-lg-100 d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "v-btn--primary",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.send
    }
  }, [_c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("문의하기")]), _c('i', {
    staticClass: "icon icon-right-arrow ml-12 ml-lg-20"
  })])], 1)], 1)])], 1), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--large"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold letter-spacing-6 line-height-1"
  }, [_vm._v(" CONTACT US DIRECTLY ")]), _c('v-divider', {
    staticClass: "border-color-secondary my-20 my-lg-40"
  }), _c('ul', {
    staticClass: "page-text--sm line-height-1"
  }, [_c('li', {
    staticClass: "d-flex mb-8 mb-lg-30"
  }, [_c('p', {
    staticClass: "info-tit font-weight-bold mr-14 mr-lg-40",
    attrs: {
      "data-aos": "fade-right"
    }
  }, [_vm._v(" Company ")]), _c('p', {
    staticClass: "color-grey-7",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_vm._v(" 민아트프레임 ")])]), _c('li', {
    staticClass: "d-flex mb-8 mb-lg-30"
  }, [_c('p', {
    staticClass: "info-tit font-weight-bold mr-14 mr-lg-40",
    attrs: {
      "data-aos": "fade-right"
    }
  }, [_vm._v(" Zip Code ")]), _c('p', {
    staticClass: "color-grey-7",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_vm._v(" 18284 ")])]), _c('li', {
    staticClass: "d-flex mb-8 mb-lg-30"
  }, [_c('p', {
    staticClass: "info-tit font-weight-bold mr-14 mr-lg-40",
    attrs: {
      "data-aos": "fade-right"
    }
  }, [_vm._v(" Address ")]), _c('p', {
    staticClass: "color-grey-7",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.google.com/maps/place/%EB%AF%BC%EC%95%84%ED%8A%B8%ED%94%84%EB%A0%88%EC%9E%84/data=!3m1!4b1!4m6!3m5!1s0x357c9f7ab2b3ec59:0x40a3fb94d5273f9f!8m2!3d37.2365537!4d126.8726446!16s%2Fg%2F1tj94b_t?hl=ko"
    }
  }, [_vm._v(" 경기도 화성시 비봉면 양노로 103 ")])])]), _c('li', {
    staticClass: "d-flex mb-8 mb-lg-30"
  }, [_c('p', {
    staticClass: "info-tit font-weight-bold mr-14 mr-lg-40",
    attrs: {
      "data-aos": "fade-right"
    }
  }, [_vm._v(" Email ")]), _c('p', {
    staticClass: "color-grey-7",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "mailto:minart369@naver.com"
    }
  }, [_vm._v("minart369@naver.com")])])]), _c('li', {
    staticClass: "d-flex mb-8 mb-lg-30"
  }, [_c('p', {
    staticClass: "info-tit font-weight-bold mr-14 mr-lg-40",
    attrs: {
      "data-aos": "fade-right"
    }
  }, [_vm._v(" Website ")]), _c('p', {
    staticClass: "color-grey-7",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("minart.com")])], 1)]), _c('li', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "info-tit font-weight-bold mr-14 mr-lg-40",
    attrs: {
      "data-aos": "fade-right"
    }
  }, [_vm._v(" Tel / Fax ")]), _c('p', {
    staticClass: "color-grey-7",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "tel:031-465-9088"
    }
  }, [_vm._v("031-465-9088")]), _vm._v(" / 031-465-9098 ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "rounded mt-md-40 mt-lg-62"
  }, [_c('iframe', {
    staticClass: "w-100 d-block",
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3176.470045870956!2d126.8726446!3d37.23655370000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f7ab2b3ec59%3A0x40a3fb94d5273f9f!2z66-87JWE7Yq47ZSE66CI7J6E!5e0!3m2!1sko!2skr!4v1675299710918!5m2!1sko!2skr",
      "width": "100%",
      "height": "366",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }